
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: { title: 'Monica Bachmann' },

    extends: View,

    mixins: [
      LoadSections([
        'hero',
        'mb-info',
        'features',
        'astro-psychology',
        'zitat',
        'hynosetherapie',
        'contact-us',
        'seminar',
        'zitat2',
        'news',
        'zitat3',
        'customer-reviews',
        'zitat4',
        'pricing-plan',
        'contact-us',
        'team',
        'zitat5',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
